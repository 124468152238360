import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'moment'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Paginacao from '../../components/paginacao/Paginacao.jsx'
import InputText    from '../_atoms/InputText'
import InputTextMask    from '../_atoms/InputTextMask'
import Label        from '../_atoms/Label'
import Helper    from '../../config/helper'

import { getList, getStructList, getBusca, geraNota, getItem } from './../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/pedidos-vazios-cozinha.json"

import Offcanvas from 'react-bootstrap/Offcanvas'

import Detalhe from './../../pages/detalhe.js'

class Lista extends React.Component {

	constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			user: user,
			modalNota: false,
			modalCliente: false,
			model:null,
			urlNota: '',
			detalheId:null,
			showCanvas:false,
			filterPayment:null,
			cliente:{},
			pedidoID:null,
			pessoaFisica:true,
			lista:[],
			struct:[],
			filtro: "todos",
			tabs: [{chave: 'todos', valor: 'TODOS'}, {chave: 'mesa', valor: 'MESA'}, {chave: 'delivery', valor: 'DELIVERY'}, {chave: 'dinein', valor: 'COMANDA'}]
		}

		this.aplicaFiltro = this.aplicaFiltro.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.handleBusca = this.handleBusca.bind(this)
		this.toggleModalNota = this.toggleModalNota.bind(this)
		this.toggleModalCliente = this.toggleModalCliente.bind(this)
		this.emiteNota = this.emiteNota.bind(this)
		this.setClientData = this.setClientData.bind(this)
		this.handleChangeDocument = this.handleChangeDocument.bind(this)
	}

	toggleModalNota(e) {
		e.preventDefault()
		if (this.state.modalNota && this.state.urlNota) {
			window.URL.revokeObjectURL(this.state.urlNota)
		}
		this.setState({
			modalNota: !this.state.modalNota,
			urlNota: ''
		})
	}

	async setClientData(name, value){
		if (value !== undefined) {
			let cliente = this.state.cliente
			cliente[name] = value

			this.setState({ cliente })
		}
	}

	async emiteNota(id){
		const result = await geraNota(this.state.user.loja.id, id)

		if (result.data && result.data.error) {
			if (result.data.error.match(/ncm/gi)) {
				Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Para emitir esta nota fiscal, é necessário informar o código NCM em produtos. Consulte seu contador para mais informações.', 'top-right')
			}	
		}
	}

	async handleSubmitCliente(e){
		e.preventDefault()
		
		if (this.state.cliente.nome === undefined || this.state.cliente.nome === "") {
			Helper.addNotification('danger', 'Atenção', 'Preencha o nome do cliente', 'top-right')
			return
		}

		if (this.state.cliente.cpf === undefined || this.state.cliente.cpf === "") {
			Helper.addNotification('danger', 'Atenção', 'Preencha o CPF do cliente', 'top-right')
			return
		}

		await geraNota(this.state.user.loja.id, this.state.pedidoID, this.state.cliente)

		const pedidos = await getList(this.props.model)
		this.setState({
			lista: pedidos
		})

		Helper.addNotification('success', 'Atenção', 'Nota gerada com sucesso', 'top-right')

		this.setState({
			modalCliente: !this.state.modalCliente,
			pedidoID: null,
			cliente: {}
		})
	}

	handleChangeDocument(){
		this.setState({
			pessoaFisica: !this.state.pessoaFisica
		})
	}

	async handleGeraNota(item){
		const id = item.id
		const detalhes = await getItem('Comanda',id)
		const pedidos = detalhes.data.pedidos

		let hasNotNCM = false
		
		pedidos.forEach( (pedido) => pedido.produtos.forEach((produto) => {
			if (!produto.ncm) {
				hasNotNCM = true
			}
		}))

		if (hasNotNCM) {
			Helper.addNotification('danger', 'Atenção', 'Para emitir nota fiscal, é necessário informar o código NCM em produtos. Consulte seu contador para mais informações.', 'top-right')
			return false
		}

		if (item.clientes.length > 0){
			await this.emiteNota(item.id)
			
			const pedidos = await getList(this.props.model)
			this.setState({
				lista: pedidos
			})

			Helper.addNotification('success', 'Atenção', 'Nota gerada com sucesso', 'top-right')

		} else {
			this.setState({
				pedidoID: item.id,
				modalCliente: !this.state.modalCliente
			})
		}
	}

	toggleModalCliente(){
		this.setState({
			modalCliente: !this.state.modalCliente,
			pedidoID: null,
			cliente: {}
		})
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getStructList(this.props.model).then(result => {
				this.setState({ struct: result })
			})

			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})
		}
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	componentDidMount() {
		this.fetchData()
	}

	validaPedidos(pedidos){
		let result = true

		pedidos.forEach(element => {
			if (element.status == "aberto") {
				result = false
			}
		})

		return result
	}

	aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
		const id = t.dataset.filter != 'todos' ? t.dataset.filter : ''

		getList(this.state.model, {filtro: id}).then(result => {
			this.setState({ lista: result })
		})
		this.setState({filtro: id === '' ? "todos" : id})
	}

	handleBusca(e){
		const valor = e.target.value 

		if (valor != ""){
			getBusca(this.state.model, {filtro:valor}).then(result => {
				this.setState({ lista: result })
			})
		} else {
			getList(this.state.model, {page:e.target.dataset.page}).then(result =>
				this.setState({ lista: result })
			)
		}

		
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	handleDetalhe(e, item){
		e.preventDefault()
		this.setState({detalheId:item.id, showCanvas:!this.state.showCanvas})
	}

	handlerFilterPayment(e, item){
		e.preventDefault()

		getList(this.state.model, {filtro:item}).then(result => {
			this.setState({ lista: result })
		})

		this.setState({filterPayment:item})
	}

	busca(event) {
		event.preventDefault()
		this.handleBusca(event)
	}

	async downloadNota(pedidoId){
		try {
			const response = await getList("pedido/nota/xml/" + this.state.user.loja.id + "/" + pedidoId)
			const htmlBlob = new Blob([response], { 
				type: 'text/html;charset=utf-8' 
			})
			const urlNota = window.URL.createObjectURL(htmlBlob)

			this.setState({urlNota:urlNota, modalNota:true})

			return () => window.URL.revokeObjectURL(urlNota)
		} catch (error) {
			console.error('Error downloading XML:', error);
		}
	}

	print(){
		try {
			// Get the iframe element
			const iframe = document.querySelector('iframe');
			
			if (!iframe) {
				console.error('Iframe not found');
				return;
			}
	
			// Focus on the iframe window
			iframe.contentWindow.focus();
			
			// Print the iframe content
			iframe.contentWindow.print();
			
		} catch (error) {
			console.error('Error printing:', error);
		}
	}

	sedToCofnig(){
		window.location.href = "/negocio/edit/Loja"
	}

	getIcon(pagamentos){
		const result = []
		const type = []
		
		for (let i in pagamentos) {
			if (!type.includes(pagamentos[i].tipo)){
			
				if (pagamentos[i].tipo === "credito") 	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#0078FF"}}></i> Crédito</span> )
				if (pagamentos[i].tipo === "pix")  		result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-brands fa-pix fs-9px fa-fw me-5px" style={{color:"#00BDAF"}}></i> PIX</span> )
				if (pagamentos[i].tipo === "dinheiro")  result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-money-bill fs-9px fa-fw me-5px" style={{color:"#0FC14A"}}></i> Dinheiro</span> )
				if (pagamentos[i].tipo === "debito")  	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#FF6F00"}}></i> Débito</span> )
				if (pagamentos[i].tipo === "vale")  	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-wallet fs-9px fa-fw me-5px" style={{color:"#0043ff"}}></i> Vale</span> )
				if (pagamentos[i].tipo === "outros")  	result.push( <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa-solid fa-money-bill fs-9px fa-fw me-5px" style={{color:"#0043ff"}}></i> Outros</span> )
			}
			type.push(pagamentos[i].tipo)
		}

		return result
	}
	
	render() {
		const lista = this.state.lista
		return (
			<div>
				
				<div className="card border-0">
					<ul className="nav nav-tabs nav-tabs-v2 px-3">
						{this.state.tabs.map( (item) =>
							<li key={item.chave} className="nav-item me-2" >
								<Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
									{item.valor}
								</Link>
							</li>
						)}
					</ul>
					
					<div className="tab-content p-3">
						<div className="input-group mb-3">
							<button className="btn btn-white" type="button" data-bs-toggle="dropdown">
								<span className="d-none d-md-inline">Buscar pedidos</span>
							</button>
							
							<div className="flex-fill position-relative">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.busca(e)}/>
								</div>
							</div>
						</div>
					</div>

					{this.state.filtro == "todos" &&
						<div>
							<div className="btn-group mb-3"  style={{float:"left", marginLeft:"20px"}}>
								<button className={this.state.filterPayment === "credito" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "credito")}>
									<i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#0078FF"}}></i> Crédito
								</button>
								<button className={this.state.filterPayment === "pix" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "pix")}>
									<i className="fa-brands fa-pix fs-9px fa-fw me-5px" style={{color:"#00BDAF"}}></i> PIX
								</button>
								<button className={this.state.filterPayment === "dinheiro" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "dinheiro")}>
									<i className="fa-solid fa-money-bill fs-9px fa-fw me-5px" style={{color:"#0FC14A"}}></i> Dinheiro
								</button>
								<button className={this.state.filterPayment === "debito" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.handlerFilterPayment(e, "debito")}>
									<i className="fa-solid fa-credit-card fs-9px fa-fw me-5px" style={{color:"#FF6F00"}}></i> Débito
								</button>
							</div>
						</div>
					}

					{lista.data && lista.data.length > 0 ? (
						<div className="tab-content p-3">
							<div className="tab-pane fade show active" id="allTab">
								
								<div className="table-responsive mb-3">
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th></th>
												<th>Pedido</th>
												<th>Data</th>
												<th>Tipo</th>
												<th>Mesa/Comanda</th>
												<th>Pagamento</th>
												<th>Itens</th>
												<th>Nota Fiscal</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											{lista.data.length > 0 && lista.data.map((item) => 
												<tr key={"item_"+item.id}>
													<td className="w-10px align-middle">
														<div className="form-check">
															<input type="checkbox" className="form-check-input" id="product1" />
															<label className="form-check-label" for="product1"></label>
														</div>
													</td>
													<td><Link to="#" onClick={(e) => this.handleDetalhe(e, item)} className="fw-bold">#{item.id}</Link></td>
													<td>{Moment(item.created_at).format('DD/MM/YYYY - HH:mm')}</td>
													<td>
														{item.b_delivery === 1 &&
															<span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> Delivery
															</span>
														}

														{item.b_takeaway === 1 &&
															<span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda 
															</span>
														}

														{item.b_delivery === 0 && item.b_takeaway === 0 && item.mesa.b_comanda === 0 &&
															<span className="badge border border-pink text-pink  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-utensils fs-9px fa-fw me-5px"></i> Mesa
															</span>
														}

														{item.b_delivery === 0 && item.b_takeaway === 0 && item.mesa.b_comanda === 1 &&
															<span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda
															</span>
														}
													</td>
													<td>{item.mesa ? item.mesa.identificador : item.id}</td>
													
													<td>
														{item.pagamentos &&  item.pagamentos.length > 0 &&
															this.getIcon(item.pagamentos)
														}
													</td>
													<td>{item.pedidos.length} itens</td>
													<td>
														{this.state.user.loja.b_notas ? (
															<>
																{item.xml_nota ? (
																	<button className="btn btn-white" style={{color:"#121212"}} onClick={(e) => this.downloadNota(item.id)}>
																		<i className="fa-solid fa-file-invoice"></i> Ver NFC-e
																	</button>
																) : (
																	<button className="btn btn-white" style={{color:"#0043ff"}} onClick={(e) => this.handleGeraNota(item)}>
																		<i className="fa-solid fa-file-invoice"></i> Gerar NFC-e
																	</button>
																)}
															</>
														):(
															<button className="btn btn-white" style={{color:"#0043ff"}} onClick={(e) => this.sedToCofnig()}>
																<i className="fa-solid fa-file-invoice"></i> Configurar Fiscal
															</button>
														)}
													</td>
													{item.b_delivery === 1 && item.pagamentos && item.pagamentos.length > 0 ? (
														<td><b>R$ {item.pedidos.length && (item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ) + parseFloat(item.pagamentos[0].valor_taxa_entrega)).toFixed(2)}</b></td>
													) : (
														<>
															{item.pagamentos && item.pagamentos.length > 0 && item.pagamentos[0].b_taxa === 1 ? (
																<td><b>R$ {item.pedidos.length && (item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ) + (item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ) * 0.1)).toFixed(2)}</b></td>
															):(
																<td><b>R$ {item.pedidos.length && item.pedidos.reduce((a,g) => a += parseFloat(g.valor), 0 ).toFixed(2)}</b></td>
															)}
														</>
													)}
												</tr>
											)}
										</tbody>
									</table>
								</div>

								{this.props.paginacao && (this.paginacao())}
							</div>
							<Modal isOpen={this.state.modalNota}>
								<ModalBody style={{textAlign:"center"}}>
									<iframe src={this.state.urlNota} style={{height:"650px"}}></iframe>
								</ModalBody>
								<ModalFooter>
									<button className="btn btn-primary" data-mesa={null} onClick={(e) => this.print(e)}>Imprimir</button>
									<button className="btn btn-white" data-mesa={null} onClick={(e) => this.toggleModalNota(e)}>Fechar</button>
								</ModalFooter>
							</Modal>

							<Modal isOpen={this.state.modalCliente}>
								<ModalHeader>
									Dados do Cliente
								</ModalHeader>
								<ModalBody style={{textAlign:"center"}}>
										<div className='row'>
											<div className="col-md-12">
												<div className="form-check form-switch col-3 mb-3" style={{float:"left"}}>
													<input className="form-check-input" name="utilizacao" type="radio" checked={this.state.pessoaFisica === true} onChange={() => {this.handleChangeDocument()}}/>
													<label className="form-check-label" htmlFor="utilizacao">CPF</label>
												</div>

												<div className="form-check form-switch col-3"  style={{float:"left"}}>
													<input className="form-check-input" name="utilizacao" type="radio" checked={this.state.pessoaFisica === false} onChange={() => {this.handleChangeDocument()}}/>
													<label className="form-check-label" htmlFor="utilizacao">CNPJ</label>
												</div>
											</div>
										</div>
										
										{this.state.pessoaFisica ? (
											<div className='row'>	
												<div className="col-md-6">
													<div className="form-floating mb-15px">
														<InputTextMask
															nome="cpf"
															setFomrData={this.setClientData}
															dadosIniciais={this.state.cliente.cpf ? this.state.cliente.cpf : ""}
															mask={"999.999.999-99"}
														/>
														<Label nome="cpf" label="CPF"/>
													</div>
												</div>

												<div className="col-md-6">
													<div className="form-floating mb-15px">
														<InputText
															nome="nome"
															setFomrData={this.setClientData}
															dadosIniciais={this.state.cliente.nome ? this.state.cliente.nome : ""}
														/>
														<Label nome="nome" label="Nome"/>
													</div>
												</div>
											</div>
										) : (
											<div className='row'>	
												<div className="col-md-6">
													<div className="form-floating mb-15px">
														<InputTextMask
															nome="cnpj"
															setFomrData={this.setClientData}
															dadosIniciais={this.state.cliente.cnpj ? this.state.cliente.cnpj : ""}
															mask={"99999999/9999-99"}
														/>
														<Label nome="cnpj" label="CNPJ"/>
													</div>
												</div>

												<div className="col-md-6">
													<div className="form-floating mb-15px">
														<InputText
															nome="razaosocial"
															setFomrData={this.setClientData}
															dadosIniciais={this.state.cliente.razaosocial ? this.state.cliente.razaosocial : ""}
														/>
														<Label nome="razaosocial" label="Rasão Social"/>
													</div>
												</div>
											</div>
										)}
										
								</ModalBody>
								<ModalFooter>
									<button className="btn btn-primary" data-mesa={null} onClick={(e) => this.handleSubmitCliente(e)}>Gerar Nota</button>
									<button className="btn btn-white" data-mesa={null} onClick={(e) => this.toggleModalCliente(e)}>Fechar</button>
								</ModalFooter>
							</Modal>

							
						</div>
					)
					:
					( 
						<div className="card border-0" style={{padding:"15px"}}>
							<Lottie animationData={lottiejson} loop={true} style={{width: "450px", margin:"auto"}} />
							<h1 style={{textAlign:"center"}}>Nenhum pedido por aqui! Aproveite seu dia... </h1>
							<p  style={{textAlign:"center"}}>De momento não tem nenhum pedido no seu estabelecimento.</p>
						</div>
					)}
				</div>

				<Offcanvas show={this.state.showCanvas} onHide={() => this.setState({showCanvas: false})}  placement="end" style={{width:"40%"}}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>
							<h3>Pedido: #{this.state.detalheId}</h3>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Detalhe id={this.state.detalheId}/>
					</Offcanvas.Body>
				</Offcanvas>
				
			</div>
		)
	}
}

export default Lista;