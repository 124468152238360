
import React from 'react'

import { saveObj, getItem, getEndereco } from '../../store/api.js'
import { AppSettings } from '../../config/app-settings.js'

import DropDown     from '../_atoms/DropDown'
import InputText    from '../_atoms/InputText'
import InputTextMask   from '../_atoms/InputTextMask'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'


import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

export default class FormLoja extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
           
            model:this.props.model,
            id:this.props.id,
            data:{
              items:{},
              relations:{}
            },
            address:{
                items:{ 'id': this.props.endereco },
                relations:{}
            },
            certificado:{}
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.setAddress = this.setAddress.bind(this)
        this.handleCertificadoSenha = this.handleCertificadoSenha.bind(this)
        this.handleCertificado = this.handleCertificado.bind(this)
      
    }

    async componentDidMount() {
        const item = await getItem(this.state.model, this.state.id)

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data}})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)
          await saveObj(this.state.address, "atualizaendereco")
          await saveObj(this.state.certificado, "createCompanies/"+this.props.id)

          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {

          console.log(e)
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    handleCertificado = (event) => {
        if (event.target.files && event.target.files[0]) {
            const certificado = this.state.certificado
            const file = event.target.files[0]
            const reader = new FileReader()
		    reader.readAsDataURL(file)

            reader.onload = async () => {
                certificado.file_name = file.name.replace(/ /g, '_')
                certificado.file = reader.result

                this.setState(certificado)
            }
        }
    }

    handleEndereco = (name, valor) => {
        const address = this.state.address

        address.items[name] = valor

        this.setState({ address })
    }

    handleCertificadoSenha = (name, valor) => {
        const certificado = this.state.certificado
        certificado.senha = valor
        this.setState(certificado)
    }

    async setAddress(valor) {
        const endereco = valor.value.terms
        const address = this.state.address

		if (endereco.length === 5) {
            address.items["estado"] = endereco[4].value
            address.items["cidade"] = endereco[3].value
            address.items["bairro"] = endereco[2].value
            address.items["numero"] = endereco[1].value
            address.items["rua"] 	= endereco[0].value
        } else {
            address.items["estado"] = endereco[3].value
            address.items["cidade"] = endereco[3].value
            address.items["bairro"] = endereco[2].value
            address.items["numero"] = endereco[0].value.split(",")[1]
            address.items["rua"] 	= endereco[0].value.split(",")[0]
        }
       
        const gps = await getEndereco(address.items["numero"]+"+"+address.items["rua"].replaceAll(" ","+")+"+"+address.items["bairro"].replaceAll(" ","+")+", "+address.items["cidade"].replaceAll(" ","+")+", "+address.items["estado"])
    
        address.items["lat"] 	 = gps.lat
        address.items["lng"] 	 = gps.lng

        this.setState({ address })
    }
    
    render() {
        const atual = this.state.data.items
       
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                        
                        <div className="card border-0 mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">FISCAL + MEU DINHEIRO</h4>
                                <p className="card-text">Essas informações são necessarias para ativar a emissão de notas fiscais no seu estabelecimento. Ao preencher corretamente, garantimos um processo fiscal alinhado as exigências legais.</p>
                            </div>
                        </div>

                        <div className="col-md-12">
                            {this.state.user.loja.b_notas ? (
                                <div class="alert alert-success alert-dismissible fade show">
                                    <strong>Conta Fiscal Criada</strong>
                                    <p>Preencha todos os dados fiscais para emitir notas fiscais pela GRUB, garantir a regularização perante a Receita Federal e manter o controle fiscal do seu negócio de forma centralizada e segura.</p>
                                </div>
                            ) : (
                                <div class="alert alert-danger alert-dismissible fade show">
                                    <strong>Informações Fiscais Não Preenchidas</strong>
                                    <p>Faltam dados fiscais! Complete todas as informações para emitir notas fiscais pela GRUB e manter tudo regularizado.</p>
                                </div>
                            )}
                        </div>

                        <div className="col-md-6">
                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="cnpj"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.cnpj : ""}
                                />
                                <Label nome="cnpj" label="CPNJ Empresa"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="ie"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.ie : ""}
                                />
                                <Label nome="ie" label="Inscrição estadual"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="csc_prod"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.csc_prod : ""}
                                />
                                <Label nome="csc_prod" label="CSC Produção"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="csc_homolog"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.csc_homolog : ""}
                                />
                                <Label nome="csc_homolog" label="CSC Homologação"/>
                            </div>

                            <div style={{margin:"27px 0"}}>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={this.handleCertificado}
                                    id="fileInput"
                                    style={{display:"none"}}
                                />
                                <label for="fileInput" class="btn btn-info">Escolha o certificado A1</label>
                            </div>

                            <div className="form-floating mb-15px">
                                <DropDown 
                                    nome="tipo_unidade"
                                    setFomrData={this.setModelData}
                                    options={[{label:"Tipo de unidade"}, {label:'Matriz'}, {label:'Filial'}]}
                                    dadosIniciais={atual ? atual.tipo_unidade : "Tipo de unidade"}
                                    label="Tipo de unidade"
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="razao_social"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.razao_social : ""}
                                />
                                <Label nome="razao_social" label="Razão Social"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="im"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.im : ""}
                                />
                                <Label nome="im" label="Inscrição municipal"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="id_csc_prod"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.id_csc_prod : ""}
                                />
                                <Label nome="id_csc_prod" label="ID CSC Produção"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="id_csc_homolog"
                                    setFomrData={this.setModelData}
                                    dadosIniciais={atual ? atual.id_csc_homolog : ""}
                                />
                                <Label nome="id_csc_homolog" label="ID CSC Homolog"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="senha_certificado"
                                    setFomrData={this.handleCertificadoSenha}
                                    dadosIniciais={this.state.certificado ? this.state.certificado.senha : ""}
                                />
                                <Label nome="senha_certificado" label="Senha Certificado A1"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <DropDown 
                                    nome="tipo_tributacao"
                                    setFomrData={this.setModelData}
                                    options={[{label:"Tipo de tributação"}, {label:'Simples'}, {label:'Presumido'}]}
                                    dadosIniciais={atual ? atual.tipo_tributacao : "Tipo de tributação"}
                                    label="Tipo de tributação"
                                />
                            </div>
                        </div>

                        <hr className="bg-gray-500" />

                        <div className="card border-0 mb-15px">
                            <div className="card-body">
                                <h4 className="card-title">Endereço</h4>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-floating mb-15px">
                                <InputTextMask
                                    nome="cep"
                                    setFomrData={this.handleEndereco}
                                    mask={"99999-999"}
                                    dadosIniciais={this.state.address.items ? this.state.address.items['cep'] : ""}
                                />
                                <Label nome="cep" label="Digite seu cep"/>
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="complemento"
                                    setFomrData={this.handleEndereco}
                                    dadosIniciais={this.state.address.items ? this.state.address.items['complemento'] : ""}
                                />
                                <Label nome="complemento" label="Complemento"/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-floating mb-15px" style={{height: "50px"}}>
                                <GooglePlacesAutocomplete apiKey="AIzaSyDT4DEK1lYFBmdqBZZhZeU6Wo1bcE2D4k4" 
                                    GooglePlacesDetailsQuery={{ fields: "geometry, formatted_address" }}
                                    apiOptions={{ language: 'br', region: 'br' }}
                                    selectProps={{
                                        onChange: this.setAddress,
                                        placeholder:"Digite um endereço com numero"
                                    }}
                                />
                            </div>
                        </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
    
        )
    }
}